/**
 * Checks if a specified component is an actual dialog.
 * @param {HTMLElement} component - The component to check.
 * @returns {HTMLDialogElement | null} The dialog element if it exists and is an instance of HTMLDialogElement; otherwise, null.
 */
const getActualDialog = (component: HTMLElement): HTMLDialogElement | null => {
  const dialog = component.querySelector<HTMLElement>('[ck-dialog]')
  return dialog && dialog instanceof HTMLDialogElement ? dialog : null
}

/**
 * Checks if a specified component has a ck-dialog-video-src attribute
 * @param component - The component to check.
 * @returns { HTMLElement | null}
 */
const getVideo = (component: HTMLElement): HTMLElement | null => {
  const video = component.querySelector<HTMLElement>('[ck-dialog-video-src]')
  return video ? video : null
}

/**
 * Closes a specified dialog.
 * @param {HTMLElement} component - The component containing the dialog needing to be closed.
 */
const closeDialog = (component: HTMLElement): void => {
  const dialog = getActualDialog(component)
  const video = getVideo(component)

  if (dialog) {
    dialog.close()
    component.classList.remove('open')

    if (video) {
      // Save the current src before clearing it
      const currentSrc = video.getAttribute('src') || ''
      if (currentSrc) {
        video.setAttribute('ck-dialog-video-src', currentSrc)
      }
      video.setAttribute('src', '')
    }
  }
}

/**
 * Handles clicks outside of the dialog.
 * @param {Event} event - The click event.
 */
const handleOutsideClick = (event: MouseEvent): void => {
  const thisComponent = (event.target as HTMLElement).closest('[ck-dialog-component]') as HTMLElement

  if (!thisComponent) return

  const dialog = getActualDialog(thisComponent)

  if (dialog && dialog.open) {
    const dialogBoundary = dialog.getBoundingClientRect()
    const clickedInside =
      event.clientX >= dialogBoundary.left &&
      event.clientX <= dialogBoundary.right &&
      event.clientY >= dialogBoundary.top &&
      event.clientY <= dialogBoundary.bottom

    if (!clickedInside) {
      closeDialog(thisComponent)
    }
  }
}

/**
 * Attaches click listeners to all elements with [ck-dialog-open="id"].
 * Where "id" matches a particular dialog component's id.
 */
const attachOpenDialogListeners = (): void => {
  const components = document.querySelectorAll<HTMLElement>('[ck-dialog-component]')
  const dialogOpeners = document.querySelectorAll('[ck-dialog-open]')

  dialogOpeners.forEach((dialogOpener) => {
    const targetDialogId = dialogOpener.getAttribute('ck-dialog-open')
    components.forEach((component) => {
      const dialog = getActualDialog(component)
      const video = getVideo(component)

      if (dialog && component.id === targetDialogId) {
        dialogOpener.addEventListener('click', () => {
          if (dialog.getAttribute('ck-dialog-non-modal')) {
            dialog.show()
            setTimeout(() => {
              document.body.style.overflow = 'visible'
            }, 300)
          } else {
            dialog.showModal()
          }
          document.body.style.overflow = 'hidden'
          component.classList.add('open')
          document.addEventListener('click', handleOutsideClick)

          if (video) {
            const savedSrc = video.getAttribute('ck-dialog-video-src')
            const currentSrc = video.getAttribute('src')
            
            // Determine which source to use
            let videoSrc = ''
            if (currentSrc && currentSrc.includes('youtube')) {
              videoSrc = currentSrc + '?&autoplay=1&mute=0'
            } else if (!currentSrc && savedSrc) {
              videoSrc = savedSrc
            } else {
              videoSrc = currentSrc || ''
            }

            video.setAttribute('src', videoSrc)
          }
        })
      }
    })
  })
}

/**
 * Initializes accessible dialogs and binds event listeners.
 * All necessary ARIA attributes and roles are added automatically.
 * 
 * @example
 * ```html
 * <!-- Button to open the dialog -->
 * <button ck-dialog-open="dialog-id">Open Dialog</button>
 * 
 * <div ck-dialog-component id="dialog-id"> <!-- this gets class of .open added when open -->
 *     <div ck-dialog-overlay></div> <!-- optional: adds a background overlay -->
 *     <dialog ck-dialog> <!-- this is what gets shown/hidden -->
 *         <h2>Dialog Title</h2>
 *         <p>Dialog content goes here</p>
 *         
 *         <!-- Optional: for video dialogs -->
 *         <iframe
 *             ck-dialog-video-src
 *             src="youtube or vimeo or cloudflare stream url"
 *             title="Video title"
 *             allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
 *             allowfullscreen="true"
 *         ></iframe>
 *         
 *         <button ck-dialog-close>Close</button> <!-- at least one close button is required -->
 *     </dialog>
 * </div>
 * ```
 * 
 * Required attributes:
 * - `ck-dialog-open="dialog-id"` on the opener button
 * - `ck-dialog-component` and `id` on the wrapper
 * - `ck-dialog` on the dialog element
 * - `ck-dialog-close` on at least one button
 * 
 * Optional attributes:
 * - `ck-dialog-overlay` for background overlay
 * - `ck-dialog-video-src` for video dialogs
 * - `ck-dialog-non-modal` for non-modal dialogs
 */
export default function initializeAccessibleDialogs(): void {
  const components = document.querySelectorAll<HTMLElement>('[ck-dialog-component]')

  components.forEach((component) => {
    const dialog = getActualDialog(component)

    if (dialog) {
      const closeButtons = component.querySelectorAll('[ck-dialog-close]')

      closeButtons.forEach((closeButton) => {
        closeButton.addEventListener('click', () => closeDialog(component))
      })

      dialog.addEventListener('close', () => {
        document.body.style.overflow = ''
        component.classList.remove('open')
        document.removeEventListener('click', handleOutsideClick)
      })
    }
  })

  attachOpenDialogListeners()
}
