import { focusableSelectorsString } from '../lib/helpers'

/**
 * Initialize and bind event listeners for accessible navigation components.
 * Ensures that keyboard navigation works as expected within the component and provides utilities for opening and closing navigation.
 * All necessary ARIA attributes and roles are added automatically.
 * 
 * @example
 * ```html
 * <header ck-nav-component> <!-- this gets class of .open added when open -->
 *     <nav>
 *         <a href="/">Logo</a>
 *         <div ck-nav> <!-- this is what gets shown/hidden -->
 *             <ul>
 *                 <li><a href="#">Link 1</a></li>
 *                 <li><a href="#">Link 2</a></li>
 *             </ul>
 *             <button ck-nav-close>Close</button> <!-- optional -->
 *         </div>
 *         <button ck-nav-toggle="navbar-id">Toggle Menu</button> <!-- value becomes aria-controls -->
 *     </nav>
 * </header>
 * ```
 */
export default function initializeAccessibleNavs(): void {
  document.querySelectorAll<HTMLElement>('[ck-nav-component]').forEach((component) => {
    const nav = component.querySelector<HTMLElement>('[ck-nav]')
    if (!nav) return

    // Setup initial ARIA attributes and roles
    const setupAriaAttributes = () => {
      // Setup navigation
      nav.setAttribute('role', 'navigation')
      nav.setAttribute('aria-label', 'Main navigation')
      nav.setAttribute('aria-hidden', 'true')

      // Setup menu structure
      const menuList = nav.querySelector('ul')
      if (menuList) {
        menuList.setAttribute('role', 'menubar')
        
        // Setup menu items
        menuList.querySelectorAll('li').forEach(li => {
          li.setAttribute('role', 'none')
          const anchor = li.querySelector('a')
          if (anchor) {
            anchor.setAttribute('role', 'menuitem')
            // If no aria-label is present, use the text content
            if (!anchor.hasAttribute('aria-label')) {
              anchor.setAttribute('aria-label', anchor.textContent?.trim() || 'Menu item')
            }
          }
        })
      }

      // Setup toggle buttons
      component.querySelectorAll<HTMLElement>('[ck-nav-toggle]').forEach(toggle => {
        const id = toggle.getAttribute('ck-nav-toggle')
        toggle.setAttribute('aria-label', toggle.getAttribute('aria-label') || 'Toggle menu')
        toggle.setAttribute('aria-controls', id || 'site-navigation')
        toggle.setAttribute('aria-expanded', 'false')
      })

      // Setup close buttons
      component.querySelectorAll<HTMLElement>('[ck-nav-close]').forEach(close => {
        if (!close.hasAttribute('aria-label')) {
          close.setAttribute('aria-label', 'Close menu')
        }
      })
    }

    let lastFocus: HTMLElement | null = null
    let navOpen = false
    let clickOutsideHandler: ((e: MouseEvent) => void) | null = null

    // Initialize ARIA attributes
    setupAriaAttributes()

    /**
     * Handle tab key within the navigation component to ensure that focus remains trapped.
     * @param {KeyboardEvent} e - The keydown event object.
     */
    const handleTabKey = (e: KeyboardEvent) => {
      if (e.key !== 'Tab') return

      const focusableEls = Array.from(nav.querySelectorAll<HTMLElement>(focusableSelectorsString))
        .filter(el => window.getComputedStyle(el).display !== 'none')

      if (!focusableEls.length) return

      const [firstFocusableEl, lastFocusableEl] = [focusableEls[0], focusableEls[focusableEls.length - 1]]
      const isFirstElement = document.activeElement === firstFocusableEl
      const isLastElement = document.activeElement === lastFocusableEl

      if (e.shiftKey && isFirstElement) {
        e.preventDefault()
        lastFocusableEl.focus()
      } else if (!e.shiftKey && isLastElement) {
        e.preventDefault()
        firstFocusableEl.focus()
      }
    }

    /**
     * Handle arrow key navigation within menu items
     * @param {KeyboardEvent} e - The keydown event object.
     */
    const handleArrowKeys = (e: KeyboardEvent) => {
      const menuItems = Array.from(nav.querySelectorAll<HTMLElement>('[role="menuitem"]'))
      if (!menuItems.length) return

      const currentIndex = menuItems.indexOf(document.activeElement as HTMLElement)
      if (currentIndex === -1) return

      let nextIndex: number
      switch (e.key) {
        case 'ArrowDown':
        case 'ArrowRight':
          e.preventDefault()
          nextIndex = currentIndex === menuItems.length - 1 ? 0 : currentIndex + 1
          menuItems[nextIndex].focus()
          break
        case 'ArrowUp':
        case 'ArrowLeft':
          e.preventDefault()
          nextIndex = currentIndex === 0 ? menuItems.length - 1 : currentIndex - 1
          menuItems[nextIndex].focus()
          break
        case 'Home':
          e.preventDefault()
          menuItems[0].focus()
          break
        case 'End':
          e.preventDefault()
          menuItems[menuItems.length - 1].focus()
          break
      }
    }

    /**
     * Handler for keydown events when navigation is open.
     * @param {KeyboardEvent} e - The keydown event object.
     */
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault()
        closeNav()
      } else if (e.key === 'Tab') {
        handleTabKey(e)
      } else {
        handleArrowKeys(e)
      }
    }

    /**
     * Remove click outside handler
     */
    const removeClickOutsideHandler = () => {
      if (clickOutsideHandler) {
        document.removeEventListener('click', clickOutsideHandler)
        clickOutsideHandler = null
      }
    }

    /**
     * Close the navigation component and remove event listeners.
     */
    const closeNav = () => {
      if (!navOpen) return

      document.body.style.overflow = ''
      component.classList.remove('open')
      nav.setAttribute('aria-hidden', 'true')
      
      component
        .querySelectorAll<HTMLElement>('[ck-nav-toggle]')
        .forEach((toggle) => toggle.setAttribute('aria-expanded', 'false'))

      navOpen = false
      document.removeEventListener('keydown', keydownHandler)
      removeClickOutsideHandler()
      
      if (lastFocus) {
        setTimeout(() => lastFocus?.focus(), 100)
      }
    }

    /**
     * Show the navigation component and add event listeners.
     */
    const showNav = () => {
      if (navOpen) return

      document.body.style.overflow = 'hidden'
      component.classList.add('open')
      nav.setAttribute('aria-hidden', 'false')
      
      component
        .querySelectorAll<HTMLElement>('[ck-nav-toggle]')
        .forEach((toggle) => toggle.setAttribute('aria-expanded', 'true'))

      clickOutsideHandler = (e: MouseEvent) => {
        const target = e.target as HTMLElement
        if (!nav.contains(target) && !target.hasAttribute('ck-nav-toggle')) {
          closeNav()
        }
      }
      
      setTimeout(() => {
        document.addEventListener('click', clickOutsideHandler!)
      }, 100)

      document.addEventListener('keydown', keydownHandler)

      navOpen = true
      lastFocus = document.activeElement as HTMLElement

      setTimeout(() => {
        const firstMenuItem = nav.querySelector<HTMLElement>('[role="menuitem"]')
        const firstFocusable = nav.querySelector<HTMLElement>(focusableSelectorsString)
        const elementToFocus = firstMenuItem || firstFocusable
        elementToFocus?.focus()
      }, 100)
    }

    // Initialize event listeners for nav toggle buttons
    component.querySelectorAll<HTMLElement>('[ck-nav-toggle]').forEach((navbarToggle) => {
      navbarToggle.addEventListener('click', (e) => {
        e.stopPropagation()
        navOpen ? closeNav() : showNav()
      })
    })

    // Initialize event listeners for nav close buttons
    component.querySelectorAll<HTMLElement>('[ck-nav-close]').forEach((closeButton) => {
      closeButton.addEventListener('click', closeNav)
    })
  })
}
