import Accordions from './components/accordion'
import Dialogs from './components/dialog'
import Nav from './components/nav'

console.info('%c🔥CK Started!', 'color: red; font-size: 1rem;')

// accessibility enhancements
Accordions()
Dialogs()
Nav()
